import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { DiscussionEmbed } from "disqus-react"

const Blog = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges
  const disqusConfig = {
    shortname: 'nickmccullum',
    config: { identifier: 'rails' },
  }

  return (
    <Layout>
      <SEO title="Rails" />
      <article className="page">
      <h1>{'Rails Course'}</h1>
        <div className="entry" >
        {posts.map(({ node }) =>
            <article className="post">

              <h2><Link to={`/${node.fields.slug}`}>{node.frontmatter.title}</Link></h2>

              <div className="entry" dangerouslySetInnerHTML={{ __html: node.excerpt }}>

              </div>

              <Link to={`/${node.fields.slug}`} className="read-more">Read More</Link>
            </article>
          )}
        </div>
        <DiscussionEmbed {...disqusConfig} />
      </article>
    </Layout>
  )
}
export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {fields: {type: {eq: "rails"}}}) {
      edges {
        node {
          excerpt
          fields {
            slug
            type
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`